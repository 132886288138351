// extracted by mini-css-extract-plugin
export var bodyBase = "level-config-edit-module--body-base--ZeE5X level-config-edit-module--site-font--VT796";
export var bodyLarge = "level-config-edit-module--body-large--W7ybs level-config-edit-module--body-base--ZeE5X level-config-edit-module--site-font--VT796";
export var bodyLargeBold = "level-config-edit-module--body-large-bold--82F-Q level-config-edit-module--body-base--ZeE5X level-config-edit-module--site-font--VT796";
export var bodyRegular = "level-config-edit-module--body-regular--6LYus level-config-edit-module--body-base--ZeE5X level-config-edit-module--site-font--VT796";
export var bodyRegularBold = "level-config-edit-module--body-regular-bold--MEXvA level-config-edit-module--body-base--ZeE5X level-config-edit-module--site-font--VT796";
export var bodySmall = "level-config-edit-module--body-small--VWz4v level-config-edit-module--body-base--ZeE5X level-config-edit-module--site-font--VT796";
export var bodySmallBold = "level-config-edit-module--body-small-bold--cS487 level-config-edit-module--body-base--ZeE5X level-config-edit-module--site-font--VT796";
export var borderTop = "level-config-edit-module--border-top--oyJKE";
export var breakWordContainer = "level-config-edit-module--break-word-container--eaWRh";
export var buttonIconBase = "level-config-edit-module--button-icon-base--x1ns+";
export var clickLink = "level-config-edit-module--click-link--zGI7j";
export var dropdownBase = "level-config-edit-module--dropdown-base--FC7CA";
export var dropdownField = "level-config-edit-module--dropdown-field--G2aYa";
export var dropdownSelectBase = "level-config-edit-module--dropdown-select-base--x4aER level-config-edit-module--text-input--PmrjD";
export var fieldWidth = "level-config-edit-module--field-width--Eskcc";
export var flexCol = "level-config-edit-module--flex-col--gKdSZ";
export var formErrorMessage = "level-config-edit-module--form-error-message--94d69";
export var h3 = "level-config-edit-module--h3--Xlz6X";
export var h4 = "level-config-edit-module--h4--DahRj";
export var hoverLink = "level-config-edit-module--hover-link--5TdLf";
export var hoverRow = "level-config-edit-module--hover-row--ootdD";
export var labelWithLock = "level-config-edit-module--label-with-lock--E9HVZ";
export var lockIcon = "level-config-edit-module--lock-icon--Oc4Gg level-config-edit-module--button-icon-base--x1ns+";
export var lockedByParent = "level-config-edit-module--locked-by-parent--pZ0O2";
export var membershipContainer = "level-config-edit-module--membership-container--EyvhC level-config-edit-module--flex-col--gKdSZ level-config-edit-module--primary-border--EsRiF";
export var membershipHeader = "level-config-edit-module--membership-header--0wOxN";
export var membershipHeading = "level-config-edit-module--membership-heading--E5R4-";
export var membershipLabel = "level-config-edit-module--membership-label--VU1OX";
export var moreFiltersBorderClass = "level-config-edit-module--more-filters-border-class--m+GTv";
export var notifIconFail = "level-config-edit-module--notif-icon-fail--I8swV";
export var notifIconSuccess = "level-config-edit-module--notif-icon-success--TS0Bb";
export var padlock = "level-config-edit-module--padlock--ulQBe";
export var pageBg = "level-config-edit-module--page-bg--0lIxH";
export var parallelField = "level-config-edit-module--parallel-field--8ee9H";
export var pointer = "level-config-edit-module--pointer--rBOv+";
export var primaryBorder = "level-config-edit-module--primary-border--EsRiF";
export var shadowBoxLight = "level-config-edit-module--shadow-box-light--kjSQw";
export var singlesAndDoubles = "level-config-edit-module--singles-and-doubles--JCC98";
export var siteFont = "level-config-edit-module--site-font--VT796";
export var slideDownAndFade = "level-config-edit-module--slideDownAndFade--J3TxY";
export var slideLeftAndFade = "level-config-edit-module--slideLeftAndFade--zWIqg";
export var slideRightAndFade = "level-config-edit-module--slideRightAndFade--MoAci";
export var slideUpAndFade = "level-config-edit-module--slideUpAndFade--3Cx5v";
export var statusDecoration = "level-config-edit-module--status-decoration--25HJG";
export var switches = "level-config-edit-module--switches--wUOTC";
export var switchesContainer = "level-config-edit-module--switches-container--tqci-";
export var switchesLocks = "level-config-edit-module--switches-locks--stcy3";
export var textInput = "level-config-edit-module--text-input--PmrjD";
export var textInverted = "level-config-edit-module--text-inverted--rrhkH";
export var textMediumDark = "level-config-edit-module--text-medium-dark--DoPoh";
export var tooltipFont = "level-config-edit-module--tooltipFont--K9Urr";
export var unstyledButton = "level-config-edit-module--unstyled-button--uBeIX";