import gql from 'graphql-tag'

const LEVEL_CONFIG_FIELDS_FRAGMENT = gql`
  fragment LevelConfigFields on LevelConfiguration {
    contextId
    levelId
    enabled
    description
    skillLevel
    selectionProcessConstraints
    doublesSelectionProcessConstraints
    ballColours
    eventLengthRange {
      minimumDays
      maximumDays
    }
    checkInRequired
    allowWildcards
    eventFormats
    doublesEventFormats
    tournamentFee
    allowsNonConsecutiveDays
    lockedProperties
    scoreFormats
    doublesScoreFormats
    teamScoreFormats
    eventTypes
    genders
    sanctionType
    teamEventFormats
    splitDrawEventFormats
    roundRobinPlayoffDrawEventFormats
    teamSelectionProcessConstraints
    goldSilverBronzeDrawsEnabled
    allowSplitDraws
    autoApprove
    headTax {
      fixedFee
      percentageFee
    }
    submissionWindow {
      from
      to
      timeZone
    }
    firstMatchLoserConsolation
    ntrpEnabled
    wtnRankingsEnabled
    rankingsEnabled
    playTracker
    allowGeneratePlayOffForBackDraws
    allowBackdrawMovements
    allowTeamSubstitutions
    allowQualifyingDraws
    allowAddIndividualPlayers
    allowAddPlayersFromTeams
    enforceScorecardGender
    enforceScorecardAge
    additionalPlayerInformation
    adornments
  }
`

export const GET_LEVEL_CONFIG = gql`
  query LevelConfig($levelId: UUID!, $orgId: UUID!) {
    level(id: $levelId) {
      id
      name
      category
      configuration(id: $orgId) {
        ...LevelConfigFields
        parent {
          contextId
          levelId
          selectionProcessConstraints
          doublesSelectionProcessConstraints
          ballColours
          eventFormats
          doublesEventFormats
          scoreFormats
          doublesScoreFormats
          teamScoreFormats
          lockedProperties
          genders
          eventTypes
          teamEventFormats
          splitDrawEventFormats
          roundRobinPlayoffDrawEventFormats
          teamSelectionProcessConstraints
        }
      }
    }
  }
  ${LEVEL_CONFIG_FIELDS_FRAGMENT}
`

export const OVERRIDE_LEVEL_CONFIG = gql`
  mutation OverrideLevelConfig(
    $levelId: UUID!
    $orgId: UUID!
    $overrides: LevelConfigurationOverridesInput!
  ) {
    setLevelConfigurationOverrides(levelId: $levelId, contextId: $orgId, overrides: $overrides) {
      ...LevelConfigFields
    }
  }
  ${LEVEL_CONFIG_FIELDS_FRAGMENT}
`
