import React from 'react'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import LevelConfigEdit from 'src/components/level-config-edit/level-config-edit'
import {  usePrivateRoute } from 'src/utils/auth'
import { Router, RouteComponentProps } from '@reach/router'

const EditLevelConfigPage = () => {
  usePrivateRoute()
  return (
    <Router basepath="/tournaments/level-config">
      <EditLevelConfigRoute path="/:levelId" />
    </Router>
  )
}

interface EditLevelConfigProps extends RouteComponentProps {
  levelId?: string
}

const EditLevelConfigRoute: React.FC<EditLevelConfigProps> = ({ levelId }) => {
  return (
    <Layout>
      <SEO title="Edit Level Configuration" />
      <LevelConfigEdit levelId={levelId} />
    </Layout>
  )
}

export default EditLevelConfigPage
