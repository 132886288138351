import React, { ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../button/button'
import { SwitchField } from '../formik-fields/formik-fields'
import Icon from '../icon/icon'
import * as styles from './level-config-edit.module.less'

type LockType = 'parent' | 'you' | undefined
export type LockedFields = { [field: string]: LockType }

export interface LockedFieldProps {
  lockedFields: LockedFields
  lockFields: (fields: string[], lock?: boolean) => void
}

interface LabelWithLockProps extends LockedFieldProps {
  label: string
  name: string // single name used to determine lock type (you/parent/none)
  alsoLock?: string[] // additionally locks these fields if passed, still only uses name prop to determine lock type
}

export const LabelWithLock: React.FC<LabelWithLockProps> = ({
  label,
  lockFields,
  lockedFields,
  name,
  alsoLock = []
}) => {
  return (
    <div className={styles.labelWithLock}>
      {label}
      <LockedIndicator
        onLock={lock => lockFields([...alsoLock, name], lock)}
        lockType={lockedFields[name]}
      />
    </div>
  )
}

interface LockedIndicatorProps {
  lockType: LockType
  onLock: (lock: boolean) => void
}

const LockedIndicator: React.FC<LockedIndicatorProps> = ({ onLock, lockType }) => {
  const { t } = useTranslation()
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      onLock(lockType !== 'you')
    },
    [onLock, lockType]
  )
  return (
    <div>
      {lockType === 'parent' ? (
        <div className={styles.lockedByParent}>
          <Icon name="sm-padlock" className={styles.padlock} />
          {t('locked')}
        </div>
      ) : (
        <Button
          size={'sm'}
          icon={lockType === 'you' ? 'sm-padlock' : undefined}
          iconClassname={styles.lockIcon}
          linkStyle
          onClick={onClick}
        >
          {t(lockType === 'you' ? 'locked by you' : 'lock this field')}
        </Button>
      )}
    </div>
  )
}

export const FieldWidth: React.FC = ({ children }) => {
  return <div className={styles.fieldWidth}>{children}</div>
}

interface SinglesAndDoublesProps {
  singles: ReactNode
  doubles: ReactNode
}

export const SinglesAndDoubles: React.FC<SinglesAndDoublesProps> = ({ singles, doubles }) => {
  return (
    <div className={styles.singlesAndDoubles}>
      <ParallelField>{singles}</ParallelField>
      <ParallelField>{doubles}</ParallelField>
    </div>
  )
}

const ParallelField: React.FC = ({ children }) => {
  return <div className={styles.parallelField}>{children}</div>
}

interface SwitchContainerProps extends LockedFieldProps {
  switches: { name: string; label: string }[]
}

export const SwitchesContainer: React.FC<SwitchContainerProps> = ({
  switches,
  lockFields,
  lockedFields
}) => {
  return (
    <div className={styles.switchesContainer}>
      <div className={styles.switches}>
        {switches.map(({ name, label }) => {
          return (
            <SwitchField key={name} name={name} label={label} disabled={!!lockedFields[name]} />
          )
        })}
      </div>
      <div className={styles.switchesLocks}>
        {switches.map(({ name }) => {
          return (
            <LockedIndicator
              key={name}
              onLock={lock => lockFields([name], lock)}
              lockType={lockedFields[name]}
            />
          )
        })}
      </div>
    </div>
  )
}
