import { useMutation, useQuery } from '@apollo/client'
import { Form, Formik, useFormikContext } from 'formik'
import { navigate } from 'gatsby'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOrgId } from 'src/apollo/local-state'
import {
  BallColour,
  Category as CategoryType,
  CheckIn,
  EventFormat,
  EventType,
  Gender,
  SanctionType,
  SelectionProcess,
  SkillLevel,
  EventLengthRangeInput
} from 'src/graphql-types/globalTournamentTypes'
import { LevelConfig, LevelConfigVariables } from 'src/graphql-types/LevelConfig'
import {
  OverrideLevelConfig,
  OverrideLevelConfigVariables
} from 'src/graphql-types/OverrideLevelConfig'
import * as Yup from 'yup'
import APIErrorMessage from '../api-error-message/api-error-message'
import Button from '../button/button'
import FloatingNotification from '../floating-notification/floating-notification'
import {
  CheckboxArrayField,
  DateField,
  DropdownField,
  Label,
  RangeField,
  TextField,
  RangeContainer
} from '../formik-fields/formik-fields'
import PageHeader from '../page-header/page-header'
import Panel from '../panel/panel'
import Spinner from '../spinner/spinner'
import { Category } from '../tournament-attributes/tournament-attributes'
import { PageMaxWidth } from '../util-components/util-components'
import { GET_LEVEL_CONFIG, OVERRIDE_LEVEL_CONFIG } from './level-config-edit-queries'
import * as styles from './level-config-edit.module.less'
import TimezoneDropdown from '../timezone-dropdown/timezone-dropdown'
import { getClientConfig } from 'src/config/config'
import { tournamentsClient } from 'src/apollo/client'
import {
  DrawAdditionalPlayerInformation,
  DrawAdornments,
  LOCKED_PROPERTY_TO_NAME,
  NAME_TO_LOCKED_PROPERTY,
  SCORE_FORMATS
} from './level-config-constants'
import {
  FieldWidth,
  LabelWithLock,
  LockedFieldProps,
  LockedFields,
  SinglesAndDoubles,
  SwitchesContainer
} from './level-config-components'

const {
  BEGINNER,
  LOW_INTERMEDIATE,
  INTERMEDIATE,
  HIGH_INTERMEDIATE,
  ADVANCED,
  NONE: SKILL_NONE
} = SkillLevel
const {
  TOP_DOWN_BY_RANKING,
  TOP_DOWN_BY_RATING,
  BOTTOM_UP_BY_RANKING,
  BOTTOM_UP_BY_RATING,
  FIRST_COME_FIRST_SERVED,
  MANUAL,
  BOTTOM_UP_BY_WTN,
  TOP_DOWN_BY_WTN
} = SelectionProcess
const { YELLOW, GREEN, ORANGE, RED } = BallColour
const { BOYS, COED, GIRLS, MIXED } = Gender
const { SINGLES, DOUBLES, TEAM } = EventType
const { NATIONAL, SECTION, DISTRICT, NONE } = SanctionType

type FormData = {
  description: string
  skillLevel: SkillLevel | null
  sanctionType: SanctionType | null
  selectionProcessConstraints: SelectionProcess[]
  doublesSelectionProcessConstraints: SelectionProcess[]
  ballColours: BallColour[]
  genders: Gender[]
  eventTypes: EventType[]
  eventLengthRange: EventLengthRangeInput | null
  checkInRequired: CheckIn
  allowWildcards: boolean
  goldSilverBronzeDrawsEnabled: boolean
  allowSplitDraws: boolean
  autoApprove: boolean
  enabled: boolean
  eventFormats: EventFormat[]
  doublesEventFormats: EventFormat[]
  scoreFormats: string[] | null
  doublesScoreFormats: string[] | null
  teamScoreFormats: string[] | null
  tournamentFee: string
  headTax: {
    percentageFee: number
    fixedFee: number
  }
  submissionWindow: {
    from: any
    to: any
    timeZone: string
  }
  allowsNonConsecutiveDays: boolean | null
  teamSelectionProcessConstraints: SelectionProcess[]
  teamEventFormats: EventFormat[]
  splitDrawEventFormats: EventFormat[]
  roundRobinPlayoffDrawEventFormats: EventFormat[]
  firstMatchLoserConsolation: boolean | null
  ntrpEnabled: boolean | null
  wtnRankingsEnabled: boolean | null
  rankingsEnabled: boolean | null
  playTracker: boolean | null
  allowGeneratePlayOffForBackDraws: boolean | null
  allowTeamSubstitutions: boolean | null
  allowBackdrawMovements: boolean | null
  allowQualifyingDraws: boolean | null
  allowAddIndividualPlayers: boolean | null
  allowAddPlayersFromTeams: boolean | null
  enforceScorecardGender: boolean | null
  enforceScorecardAge: boolean | null
  adornments: string | null
  additionalPlayerInformation: string | null
}

interface Props {
  levelId?: string
}

const EditLevelConfig: React.FC<Props> = ({ levelId }) => {
  const { t } = useTranslation()
  const orgId = useOrgId()
  const { data, loading, error } = useQuery<LevelConfig, LevelConfigVariables>(GET_LEVEL_CONFIG, {
    client: tournamentsClient,
    variables: { levelId, orgId }
  })

  const [overrideLevel, { loading: updating, error: updateError, data: updateData }] = useMutation<
    OverrideLevelConfig,
    OverrideLevelConfigVariables
  >(OVERRIDE_LEVEL_CONFIG, { client: tournamentsClient })

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        eventLengthRange: Yup.object({
          maximumDays: Yup.number()
            .min(0, t('min is 0'))
            .nullable(),
          minimumDays: Yup.number()
            .min(0, t('min is 0'))
            .nullable()
            .test('level days range', t('max > min'), function(min) {
              const max = this.parent.maximumDays
              return !(max && min > max && max > 0)
            })
        }),
        headTax: Yup.object({
          // .required() seems not to work on nested so using typeError()
          percentageFee: Yup.number().typeError(t('is required', { type: 'percentage fee' })),
          fixedFee: Yup.number().typeError(t('is required', { type: 'fixed fee' }))
        }),
        tournamentFee: Yup.number()
          .required(t('is required', { type: 'tournament fee' }))
          .test(
            'free-or-min',
            t('fee zero or min', { min: (0.5).toFixed(2) }),
            v => v >= 0.5 || v === 0
          )
      }),
    [t]
  )

  const initialValues = useMemo<FormData | undefined>(() => {
    if (data?.level?.configuration) {
      const c = data.level.configuration
      const ht = c.headTax
      const sw = c.submissionWindow
      return {
        description: c.description,
        skillLevel: c.skillLevel,
        sanctionType: c.sanctionType,
        selectionProcessConstraints: c.selectionProcessConstraints,
        doublesSelectionProcessConstraints: c.doublesSelectionProcessConstraints,
        ballColours: c.ballColours,
        genders: c.genders,
        eventTypes: c.eventTypes,
        eventLengthRange: {
          maximumDays: c.eventLengthRange?.maximumDays,
          minimumDays: c.eventLengthRange?.minimumDays
        },
        checkInRequired: c.checkInRequired,
        allowWildcards: c.allowWildcards,
        goldSilverBronzeDrawsEnabled: c.goldSilverBronzeDrawsEnabled,
        allowSplitDraws: c.allowSplitDraws,
        autoApprove: c.autoApprove,
        enabled: c.enabled,
        eventFormats: c.eventFormats,
        doublesEventFormats: c.doublesEventFormats,
        scoreFormats: c.scoreFormats,
        doublesScoreFormats: c.doublesScoreFormats,
        teamScoreFormats: c.teamScoreFormats,
        tournamentFee: (c.tournamentFee ? c.tournamentFee / 100 : 0).toFixed(2).toString(),
        headTax: ht
          ? { percentageFee: ht.percentageFee, fixedFee: ht.fixedFee / 100 }
          : { fixedFee: 0, percentageFee: 0 },
        submissionWindow: {
          from: sw?.from ?? null,
          to: sw?.to ?? null,
          timeZone: sw?.timeZone ?? 'America/New_York'
        },
        allowsNonConsecutiveDays: c.allowsNonConsecutiveDays,
        teamEventFormats: c.teamEventFormats,
        splitDrawEventFormats: c.splitDrawEventFormats,
        roundRobinPlayoffDrawEventFormats: c.roundRobinPlayoffDrawEventFormats,
        teamSelectionProcessConstraints: c.teamSelectionProcessConstraints,
        allowGeneratePlayOffForBackDraws: c.allowGeneratePlayOffForBackDraws,
        allowBackdrawMovements: c.allowBackdrawMovements,
        allowTeamSubstitutions: c.allowTeamSubstitutions,
        firstMatchLoserConsolation: c.firstMatchLoserConsolation,
        ntrpEnabled: c.ntrpEnabled,
        wtnRankingsEnabled: c.wtnRankingsEnabled,
        rankingsEnabled: c.rankingsEnabled,
        playTracker: c.playTracker,
        allowQualifyingDraws: c.allowQualifyingDraws,
        allowAddIndividualPlayers: c.allowAddIndividualPlayers,
        allowAddPlayersFromTeams: c.allowAddPlayersFromTeams,
        enforceScorecardGender: c.enforceScorecardGender,
        enforceScorecardAge: c.enforceScorecardAge,
        additionalPlayerInformation: c.additionalPlayerInformation,
        adornments: c.adornments
      }
    }
  }, [data])

  const [lockedFields, setLockedFields] = useState<LockedFields>({})

  useEffect(() => {
    if (data?.level?.configuration) {
      const locked: LockedFields = {}
      data.level.configuration.lockedProperties.forEach(
        p => (locked[LOCKED_PROPERTY_TO_NAME[p]] = 'you')
      )
      data.level.configuration.parent?.lockedProperties.forEach(
        p => (locked[LOCKED_PROPERTY_TO_NAME[p]] = 'parent')
      )
      setLockedFields(locked)
    }
  }, [data, setLockedFields])

  const lockFields = useCallback(
    (fields: string[], lock = true) => {
      const lockUpdate = fields.reduce((obj, f) => ({ ...obj, [f]: lock ? 'you' : undefined }), {})
      setLockedFields({ ...lockedFields, ...lockUpdate })
    },
    [setLockedFields, lockedFields]
  )

  const [notification, setNotification] = useState<'error' | 'success'>()
  useEffect(() => {
    if (updateError) setNotification('error')
    else if (updateData) setNotification('success')
  }, [updateError, updateData, setNotification])

  const onSubmit = useCallback(
    (overrideFields: FormData) => {
      if (!overrideFields) return
      const lockedFieldsArray = Object.entries(lockedFields)
      const lockedProperties = lockedFieldsArray
        .filter(([_, lock]) => lock === 'you')
        .map(([field]) => NAME_TO_LOCKED_PROPERTY[field])

      // we can't send overrides of locked properties, even if they are the same
      const nonLockedOverrides = { ...overrideFields }
      lockedFieldsArray.forEach(([field, lock]) => {
        if (lock === 'parent') {
          nonLockedOverrides[field] = undefined
        }
      })

      const pureOverrides = { ...nonLockedOverrides }

      const overrides = {
        ...pureOverrides,
        lockedProperties,
        tournamentFee: pureOverrides.tournamentFee ? +pureOverrides.tournamentFee * 100 : undefined,
        headTax: pureOverrides.headTax
          ? { ...pureOverrides.headTax, fixedFee: pureOverrides.headTax.fixedFee * 100 }
          : undefined
      }
      overrideLevel({ variables: { orgId, levelId, overrides } })
    },
    [orgId, levelId, overrideLevel, lockedFields, initialValues]
  )

  const parent = data?.level?.configuration.parent ?? {}
  const excludeCategory =
    data?.level?.category &&
    [CategoryType.WTN_PLAY, CategoryType.PICKLE_BALL].includes(data.level.category)

  return (
    <PageMaxWidth>
      <PageHeader
        title={
          data ? (
            <>
              {!excludeCategory && (
                <>
                  <Category category={data.level?.category} />{' '}
                </>
              )}
              {data.level?.name}
            </>
          ) : (
            t('loading level config')
          )
        }
      />
      <Panel>
        {loading && <Spinner />}
        {initialValues && (
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            <Form>
              <FormFields
                {...{ lockFields, lockedFields }}
                {...parent}
                isJuniorLevel={data?.level?.category === CategoryType.JUNIOR}
              />
              <div>
                <Button
                  loading={updating}
                  spacing={{ margins: { lg: 'top' } }}
                  size="md"
                  type="submit"
                >
                  {t('save')}
                </Button>
              </div>
            </Form>
          </Formik>
        )}
        <APIErrorMessage error={error?.message ?? updateError?.message} />
      </Panel>

      {notification && (
        <FloatingNotification
          onClose={() => setNotification(undefined)}
          message={t(notification === 'error' ? 'level config failed' : 'level config saved')}
          icon={
            notification === 'error'
              ? { name: 'sm-warning', className: styles.notifIconFail }
              : { name: 'md-tick', className: styles.notifIconSuccess }
          }
          actionButton={
            notification === 'success' && (
              <Button
                linkStyle
                size={'sm'}
                onClick={() =>
                  navigate(
                    `/tournaments/level-config?category=${data?.level?.category.toLowerCase()}`
                  )
                }
              >
                {t('back')}
              </Button>
            )
          }
        />
      )}
    </PageMaxWidth>
  )
}

interface FormFieldsProps extends LockedFieldProps {
  selectionProcessConstraints?: SelectionProcess[]
  doublesSelectionProcessConstraints?: SelectionProcess[]
  eventFormats?: EventFormat[]
  doublesEventFormats?: EventFormat[]
  ballColours?: BallColour[]
  genders?: Gender[]
  eventTypes?: EventType[]
  scoreFormats?: string[]
  doublesScoreFormats?: string[]
  teamScoreFormats?: string[]
  isJuniorLevel?: boolean
  teamEventFormats?: EventFormat[]
  splitDrawEventFormats?: EventFormat[]
  roundRobinPlayoffDrawEventFormats?: EventFormat[]
  teamSelectionProcessConstraints?: SelectionProcess[]
}

const FormFields: React.FC<FormFieldsProps> = ({
  children,
  selectionProcessConstraints,
  doublesSelectionProcessConstraints,
  eventFormats,
  doublesEventFormats,
  scoreFormats,
  doublesScoreFormats,
  teamScoreFormats,
  ballColours,
  genders,
  eventTypes,
  isJuniorLevel,
  teamEventFormats,
  splitDrawEventFormats,
  roundRobinPlayoffDrawEventFormats,
  teamSelectionProcessConstraints,
  ...lockedProps
}) => {
  const { t } = useTranslation()
  const { lockedFields } = lockedProps
  const { values } = useFormikContext<FormData>()
  const clientConfig = useMemo(getClientConfig, [])

  const alphabetical = useCallback((array: any[]) => {
    return [...array].sort((a, b) => {
      const [labelA, labelB] = [a, b].map(l => l.label.toLowerCase())
      if (labelA < labelB) return -1
      if (labelA > labelB) return 1
      return 0
    })
  }, [])

  const ballColourOptions = useMemo(() => {
    return [
      { label: t('yellow'), value: YELLOW },
      { label: t('green'), value: GREEN },
      { label: t('orange'), value: ORANGE },
      { label: t('red'), value: RED }
    ].filter(o => ballColours?.includes(o.value) ?? true)
  }, [ballColours])

  const genderOptions = useMemo(() => {
    return [
      { label: t(isJuniorLevel ? 'boys' : 'mens'), value: BOYS },
      { label: t(isJuniorLevel ? 'girls' : 'womens'), value: GIRLS },
      { label: t('mixed'), value: MIXED },
      { label: t('coeds'), value: COED }
    ].filter(o => genders?.includes(o.value) ?? true)
  }, [genders])

  const eventTypeOptions = useMemo(() => {
    return [
      { label: t('singles'), value: SINGLES },
      { label: t('doubles'), value: DOUBLES },
      { label: t('team'), value: TEAM }
    ].filter(o => eventTypes?.includes(o.value) ?? true)
  }, [eventTypes])

  const selectionProcessConstraintsOptions = useMemo(() => {
    const options = alphabetical([
      { label: t('top down ranking'), value: TOP_DOWN_BY_RANKING },
      { label: t('top down rating'), value: TOP_DOWN_BY_RATING },
      { label: t('bottom up ranking'), value: BOTTOM_UP_BY_RANKING },
      { label: t('bottom up rating'), value: BOTTOM_UP_BY_RATING },
      { label: t('first come first served'), value: FIRST_COME_FIRST_SERVED },
      { label: t('manual'), value: MANUAL },
      { label: t('top down by wtn'), value: TOP_DOWN_BY_WTN },
      { label: t('bottom up by wtn'), value: BOTTOM_UP_BY_WTN }
    ])
    return {
      singles: options.filter(o => selectionProcessConstraints?.includes(o.value)) ?? true,
      doubles: options.filter(o => doublesSelectionProcessConstraints?.includes(o.value)) ?? true,
      team: options.filter(o => teamSelectionProcessConstraints?.includes(o.value)) ?? true
    }
  }, [
    selectionProcessConstraints,
    doublesSelectionProcessConstraints,
    teamSelectionProcessConstraints
  ])

  const eventFormatsOptions = useMemo(() => {
    const opts = alphabetical(
      Object.values(EventFormat).map(k => ({ label: t(`draw type ${k.toLowerCase()}`), value: k }))
    )
    return {
      singles: opts.filter(o => eventFormats?.includes(o.value)) ?? true,
      doubles: opts.filter(o => doublesEventFormats?.includes(o.value)) ?? true,
      team: opts.filter(o => teamEventFormats?.includes(o.value)) ?? true,
      splitDraws: opts.filter(o => splitDrawEventFormats?.includes(o.value)) ?? true,
      roundRobinPlayoff:
        opts.filter(o => roundRobinPlayoffDrawEventFormats?.includes(o.value)) ?? true
    }
  }, [
    alphabetical,
    t,
    eventFormats,
    doublesEventFormats,
    teamEventFormats,
    splitDrawEventFormats,
    roundRobinPlayoffDrawEventFormats
  ])

  const scoreFormatsOptions = useMemo(() => {
    const formats = alphabetical(
      SCORE_FORMATS.map(k => ({ label: t(`tods ${k.replace(/:/g, '-')}`), value: k }))
    )
    return {
      singles: formats.filter(o => scoreFormats?.includes(o.value)) ?? true,
      doubles: formats.filter(o => doublesScoreFormats?.includes(o.value)) ?? true,
      team: formats.filter(o => teamScoreFormats?.includes(o.value)) ?? true
    }
  }, [scoreFormats, doublesScoreFormats, teamScoreFormats])

  return (
    <>
      <FieldWidth>
        <SwitchesContainer
          switches={[{ name: 'enabled', label: t('level enabled') }]}
          {...lockedProps}
        />

        <DropdownField
          name="skillLevel"
          disabled={!!lockedFields['skillLevel']}
          label={<LabelWithLock {...lockedProps} label={t('skill level')} name="skillLevel" />}
          options={[
            { label: t('none'), value: SKILL_NONE },
            { label: t('beginner'), value: BEGINNER },
            { label: t('low intermediate'), value: LOW_INTERMEDIATE },
            { label: t('intermediate'), value: INTERMEDIATE },
            { label: t('high intermediate'), value: HIGH_INTERMEDIATE },
            { label: t('advanced'), value: ADVANCED }
          ]}
        />
        <DropdownField
          name="sanctionType"
          disabled={!!lockedFields['sanctionType']}
          label={<LabelWithLock {...lockedProps} label={t('sanction type')} name="sanctionType" />}
          options={[
            { label: t('district'), value: DISTRICT },
            { label: t('section'), value: SECTION },
            { label: t('national'), value: NATIONAL }
          ]}
        />
        <TextField
          disabled={!!lockedFields['description']}
          name={'description'}
          label={
            <LabelWithLock {...lockedProps} label={t('level description')} name="description" />
          }
        />
        <CheckboxArrayField
          name="genders"
          disabled={!!lockedFields['genders']}
          label={<LabelWithLock {...lockedProps} label={t('gender')} name="genders" />}
          options={genderOptions}
        />
        <CheckboxArrayField
          name="eventTypes"
          disabled={!!lockedFields['eventTypes']}
          label={<LabelWithLock {...lockedProps} label={t('event type')} name="eventTypes" />}
          options={eventTypeOptions}
        />
      </FieldWidth>
      <SinglesAndDoubles
        singles={
          <CheckboxArrayField
            disabled={!!lockedFields['selectionProcessConstraints']}
            name="selectionProcessConstraints"
            label={
              <LabelWithLock
                {...lockedProps}
                label={t('category selection process', { category: 'singles' })}
                name="selectionProcessConstraints"
              />
            }
            options={selectionProcessConstraintsOptions.singles}
          />
        }
        doubles={
          <CheckboxArrayField
            disabled={!!lockedFields['doublesSelectionProcessConstraints']}
            name="doublesSelectionProcessConstraints"
            label={
              <LabelWithLock
                {...lockedProps}
                label={t('category selection process', { category: 'doubles' })}
                name="doublesSelectionProcessConstraints"
              />
            }
            options={selectionProcessConstraintsOptions.doubles}
          />
        }
      />
      <FieldWidth>
        <CheckboxArrayField
          name="teamSelectionProcessConstraints"
          disabled={!!lockedFields['teamSelectionProcessConstraints']}
          label={
            <LabelWithLock
              {...lockedProps}
              label={t('category selection process', { category: 'team' })}
              name="teamSelectionProcessConstraints"
            />
          }
          options={selectionProcessConstraintsOptions.team}
        />
        <CheckboxArrayField
          name="ballColours"
          disabled={!!lockedFields['ballColours']}
          label={<LabelWithLock {...lockedProps} label={t('ball color')} name="ballColours" />}
          options={ballColourOptions}
        />
        <Label>
          <LabelWithLock
            name={'submissionWindow'}
            label={t('submission window')}
            {...lockedProps}
          />
        </Label>
        <RangeContainer>
          <DateField
            name={'submissionWindow.from'}
            datePickerProps={{
              disabled: !!lockedFields['submissionWindow'],
              placeholderText: t('no restriction'),
              showTimeInput: true,
              spacing: { margins: { md: 'right' } },
              dateFormat: 'MMMM d, yyyy h:mm aa'
            }}
          />
          -
          <DateField
            name={'submissionWindow.to'}
            datePickerProps={{
              disabled: !!lockedFields['submissionWindow'],
              placeholderText: t('no restriction'),
              showTimeInput: true,
              spacing: { margins: { md: 'left' } },
              openToDate: new Date(values?.submissionWindow?.from)
            }}
          />
        </RangeContainer>
        <TimezoneDropdown
          name="submissionWindow.timeZone"
          disabled={!!lockedFields['submissionWindow']}
        />
        <RangeField
          label={
            <LabelWithLock {...lockedProps} label={t('number of days')} name="eventLengthRange" />
          }
          disabled={!!lockedFields['eventLengthRange']}
          fields={[
            {
              name: 'eventLengthRange.minimumDays',
              label: t('from'),
              textFieldProps: { inputProps: { min: 0 } }
            },
            {
              name: 'eventLengthRange.maximumDays',
              label: t('to'),
              textFieldProps: { inputProps: { min: 0 } }
            }
          ]}
        />
        <SwitchesContainer
          switches={[
            { name: 'allowsNonConsecutiveDays', label: t('non consecutive days') },
            { name: 'allowWildcards', label: t('wild cards') },
            { name: 'goldSilverBronzeDrawsEnabled', label: t('gold silver bronze draws') },
            { name: 'allowSplitDraws', label: t('allow split draws') },
            { name: 'autoApprove', label: t('automatic tournament approval') },
            { name: 'allowGeneratePlayOffForBackDraws', label: t('generate playoff backdraws') },
            { name: 'allowBackdrawMovements', label: t('allow backdraw movements') },
            { name: 'allowTeamSubstitutions', label: t('allow team substitutions') },
            { name: 'firstMatchLoserConsolation', label: t('vc entry policy') },
            { name: 'ntrpEnabled', label: t('ntrp') },
            { name: 'wtnRankingsEnabled', label: t('wtn') },
            { name: 'rankingsEnabled', label: t('rankings') },
            { name: 'playTracker', label: t('playtracker') },
            { name: 'allowQualifyingDraws', label: t('allow qualifying draws') },
            { name: 'allowAddIndividualPlayers', label: t('allow add individual players') },
            { name: 'allowAddPlayersFromTeams', label: t('allow add players from teams') },
            { name: 'enforceScorecardGender', label: t('enforce gender scorecard rules') },
            { name: 'enforceScorecardAge', label: t('enforce age scorecard rules') }
          ]}
          {...lockedProps}
        />
        <DropdownField
          name="additionalPlayerInformation"
          disabled={!!lockedFields['additionalPlayerInformation']}
          label={
            <LabelWithLock
              {...lockedProps}
              label={`${t('draw defaults')} - ${t('additional player information')}`}
              name="additionalPlayerInformation"
            />
          }
          options={[
            { label: t('none'), value: DrawAdditionalPlayerInformation.NONE },
            { label: t('city/state'), value: DrawAdditionalPlayerInformation.CITY_STATE },
            { label: t('district'), value: DrawAdditionalPlayerInformation.DISTRICT },
            { label: t('section'), value: DrawAdditionalPlayerInformation.SECTION },
            { label: t('country'), value: DrawAdditionalPlayerInformation.COUNTRY },
            { label: t('team'), value: DrawAdditionalPlayerInformation.TEAM }
          ]}
        />
        <DropdownField
          name="adornments"
          disabled={!!lockedFields['adornments']}
          label={
            <LabelWithLock
              {...lockedProps}
              label={`${t('draw defaults')} - ${t('adornments')}`}
              name="adornments"
            />
          }
          options={[
            { label: t('none'), value: DrawAdornments.NONE },
            { label: t('wtn'), value: DrawAdornments.WTN }
          ]}
        />
        <DropdownField
          name="checkInRequired"
          disabled={!!lockedFields['checkInRequired']}
          label={
            <LabelWithLock {...lockedProps} label={t('event check in')} name="checkInRequired" />
          }
          options={[
            { label: t('mandatory'), value: CheckIn.MANDATORY },
            { label: t('optional'), value: CheckIn.OPTIONAL },
            { label: t('forbidden'), value: CheckIn.FORBIDDEN }
          ]}
        />
        <TextField
          name="tournamentFee"
          currencyInput
          label={
            <LabelWithLock {...lockedProps} label={t('tournament fee')} name="tournamentFee" />
          }
          disabled={!!lockedFields['tournamentFee']}
        />
        {clientConfig.headTaxConfigurable && (
          <RangeField
            label={<LabelWithLock {...lockedProps} label={t('head tax')} name="headTax" />}
            disabled={!!lockedFields['headTax']}
            fields={[
              {
                name: 'headTax.fixedFee',
                label: t('fixed fee'),
                textFieldProps: { currencyInput: true }
              },
              {
                name: 'headTax.percentageFee',
                label: t('percentage fee'),
                textFieldProps: { percentageInput: true }
              }
            ]}
          />
        )}
      </FieldWidth>
      <SinglesAndDoubles
        singles={
          <CheckboxArrayField
            name="eventFormats"
            disabled={!!lockedFields['eventFormats']}
            label={
              <LabelWithLock
                {...lockedProps}
                label={t('category draw type', { category: 'singles' })}
                name="eventFormats"
              />
            }
            options={eventFormatsOptions.singles}
          />
        }
        doubles={
          <CheckboxArrayField
            name="doublesEventFormats"
            disabled={!!lockedFields['doublesEventFormats']}
            label={
              <LabelWithLock
                {...lockedProps}
                label={t('category draw type', { category: 'doubles' })}
                name="doublesEventFormats"
              />
            }
            options={eventFormatsOptions.doubles}
          />
        }
      />
      <FieldWidth>
        <CheckboxArrayField
          name="teamEventFormats"
          disabled={!!lockedFields['teamEventFormats']}
          label={
            <LabelWithLock {...lockedProps} label={t('team draw type')} name="teamEventFormats" />
          }
          options={eventFormatsOptions.team}
        />
      </FieldWidth>
      <FieldWidth>
        <CheckboxArrayField
          name="splitDrawEventFormats"
          disabled={!!lockedFields['splitDrawEventFormats']}
          label={
            <LabelWithLock
              {...lockedProps}
              label={t('split draws draw type')}
              name="splitDrawEventFormats"
            />
          }
          options={eventFormatsOptions.splitDraws}
        />
      </FieldWidth>
      <FieldWidth>
        <CheckboxArrayField
          name="roundRobinPlayoffDrawEventFormats"
          disabled={!!lockedFields['roundRobinPlayoffDrawEventFormats']}
          label={
            <LabelWithLock
              {...lockedProps}
              label={t('round robin playoff draw type')}
              name="roundRobinPlayoffDrawEventFormats"
            />
          }
          options={eventFormatsOptions.roundRobinPlayoff}
        />
      </FieldWidth>
      <SinglesAndDoubles
        singles={
          <CheckboxArrayField
            name="scoreFormats"
            disabled={!!lockedFields['scoreFormats']}
            label={
              <LabelWithLock
                {...lockedProps}
                label={t('category score format', { category: 'singles' })}
                name="scoreFormats"
              />
            }
            options={scoreFormatsOptions.singles}
          />
        }
        doubles={
          <CheckboxArrayField
            name="doublesScoreFormats"
            disabled={!!lockedFields['doublesScoreFormats']}
            label={
              <LabelWithLock
                {...lockedProps}
                label={t('category score format', { category: 'doubles' })}
                name="doublesScoreFormats"
              />
            }
            options={scoreFormatsOptions.doubles}
          />
        }
      ></SinglesAndDoubles>
      <FieldWidth>
        <CheckboxArrayField
          name="teamScoreFormats"
          disabled={!!lockedFields['teamScoreFormats']}
          label={
            <LabelWithLock
              {...lockedProps}
              label={t('category score format', { category: 'team' })}
              name="teamScoreFormats"
            />
          }
          options={scoreFormatsOptions.team}
        />
      </FieldWidth>
    </>
  )
}

export default EditLevelConfig
