export enum DrawAdditionalPlayerInformation {
  NONE = 'NONE',
  CITY_STATE = 'CITY_STATE',
  DISTRICT = 'DISTRICT',
  SECTION = 'SECTION',
  COUNTRY = 'COUNTRY',
  TEAM = 'TEAM',
  CONFERENCE = 'CONFERENCE',
  REGION = 'REGION'
}

export enum DrawAdornments {
  NONE = 'NONE',
  WTN = 'WTN'
}

export const SCORE_FORMATS = [
  'SET3-S:6/TB7',
  'SET3-S:6/TB7-F:TB10',
  'SET3-S:6/TB7-F:TB7',
  'SET3-S:4/TB7-F:TB7',
  'SET3-S:4/TB7-F:TB10',
  'SET3-S:4/TB7',
  'SET3-S:4/TB5NOAD@3',
  'SET1-S:6/TB7',
  'SET1-S:4/TB5@3',
  'SET3-S:TB10',
  'SET1-S:TB10',
  'SET1-S:T20',
  'SET1-S:8/TB7',
  'SET1-S:4/TB7',
  'SET1-S:6NOAD',
  'SET1-S:4NOAD',
  'SET3-S:4NOAD-F:TB7',
  'SET1-S:8/TB7@7',
  'SET1-S:5/TB5NOAD@4',
  'SET3-S:6/TB7@5',
  'SET5-S:6/TB7-F:6/TB10',
  'SET3-S:6/TB7@5-F:TB10',
  'SET1-S:6/TB5NOAD@5',
  'SET3-S:4/TB7@3',
  'SET1-S:TB15NOAD',
  'SET1-S:TB15',
  'SET1-S:TB11NOAD',
  'SET1-S:TB11',
  'SET3-S:TB11NOAD',
  'SET3-S:TB11',
  'SET3-S:TB11NOAD-F:TB7NOAD',
  'SET3-S:TB11-F:TB7'
]

export const LOCKED_PROPERTY_TO_NAME = {
  SELECTION_PROCESS_CONSTRAINTS: 'selectionProcessConstraints',
  DOUBLES_SELECTION_PROCESS_CONSTRAINTS: 'doublesSelectionProcessConstraints',
  DESCRIPTION: 'description',
  SKILL_LEVEL: 'skillLevel',
  SANCTION_TYPE: 'sanctionType',
  BALL_COLOURS: 'ballColours',
  GENDERS: 'genders',
  EVENT_TYPES: 'eventTypes',
  ALLOWS_NONCONSECUTIVE_DAYS: 'allowsNonConsecutiveDays',
  ALLOW_WILDCARDS: 'allowWildcards',
  GOLD_SILVER_BRONZE_DRAWS_ENABLED: 'goldSilverBronzeDrawsEnabled',
  ALLOW_SPLIT_DRAWS: 'allowSplitDraws',
  AUTO_APPROVE: 'autoApprove',
  ENABLED: 'enabled',
  EVENT_FORMATS: 'eventFormats',
  DOUBLES_EVENT_FORMATS: 'doublesEventFormats',
  SCORE_FORMATS: 'scoreFormats',
  DOUBLES_SCORE_FORMATS: 'doublesScoreFormats',
  TEAM_SCORE_FORMATS: 'teamScoreFormats',
  CHECKIN_REQUIRED: 'checkInRequired',
  EVENT_LENGTH_RANGE: 'eventLengthRange',
  MINIMUM_DAYS: 'minimumDays', // Kept for legacy reasons
  MAXIMUM_DAYS: 'maximumDays', // Kept for legacy reasons
  TOURNAMENT_FEE: 'tournamentFee',
  HEAD_TAX: 'headTax',
  SUBMISSION_WINDOW: 'submissionWindow',
  TEAM_SELECTION_PROCESS_CONSTRAINTS: 'teamSelectionProcessConstraints',
  TEAM_EVENT_FORMATS: 'teamEventFormats',
  SPLIT_DRAW_EVENT_FORMATS: 'splitDrawEventFormats',
  RR_PLAYOFF_DRAW_EVENT_FORMATS: 'roundRobinPlayoffDrawEventFormats',
  ALLOW_GENERATE_PLAYOFF_FOR_BACKDRAWS: 'allowGeneratePlayOffForBackDraws',
  ALLOW_BACKDRAW_MOVEMENTS: 'allowBackdrawMovements',
  ALLOW_TEAM_SUBSTITUTIONS: 'allowTeamSubstitutions',
  FIRST_MATCH_LOSER_CONSOLATION: 'firstMatchLoserConsolation',
  NTRP_ENABLED: 'ntrpEnabled',
  WTN_RANKINGS_ENABLED: 'wtnRankingsEnabled',
  RANKINGS_ENABLED: 'rankingsEnabled',
  PLAY_TRACKER: 'playTracker',
  ALLOW_QUALIFYING_DRAWS: 'allowQualifyingDraws',
  ALLOW_ADD_INDIVIDUAL_PLAYERS: 'allowAddIndividualPlayers',
  ALLOW_ADD_PLAYERS_FROM_TEAMS: 'allowAddPlayersFromTeams',
  ENFORCE_SCORECARD_RULES: 'enforceScorecardRules',
  ENFORCE_SCORECARD_GENDER: 'enforceScorecardGender',
  ENFORCE_SCORECARD_AGE: 'enforceScorecardAge',
  ADDITIONAL_PLAYER_INFORMATION: 'additionalPlayerInformation',
  ADORNMENTS: 'adornments'
}

export const NAME_TO_LOCKED_PROPERTY = Object.entries(LOCKED_PROPERTY_TO_NAME).reduce(
  (c, [lockedProperty, name]) => {
    c[name] = lockedProperty
    return c
  },
  {}
)
